<template>
  <div class="ai-template-page">
    <div
      class="container-md col-lg-8 col-md-8"
      v-if="!isProcessing && !htmlString"
    >
      <CCard>
        <CCardBody>
          <h5 class="text-primary">Profile Page Builder</h5>
          <ValidationObserver ref="templateForm" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)" name="templateForm">
              <CRow>
                <CCol md="6">
                  <CRow class="mb-3">
                    <label class="col-md-12 required">Profile Name</label>
                    <CCol md="12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="name"
                          :value="userData.name"
                          @input="handleChangeInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                  <CRow class="mb-3">
                    <label class="col-md-12 required">Content Document</label>
                    <CCol md="12">
                      <input
                        id="document-upload"
                        name="upload-document"
                        type="file"
                        ref="fileref"
                        :accept="arrayToComma(acceptedFile)"
                        @change="selectedFile"
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol md="6">
                  <CRow class="mb-3">
                    <label class="col-md-12 required">Short Description</label>
                    <CCol md="12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextAreaInput
                          name="description"
                          :value="userData.description"
                          @input="handleChangeInput"
                          :error="errors[0]"
                          :rows="5"
                          CSS_Style="resize: none;"
                        />
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>

              <div class="d-flex justify-content-end">
                <CButton
                  type="submit"
                  color="primary"
                  shape="pill"
                  :disabled="isGenerating"
                >
                  <CSpinner
                    v-if="isGenerating"
                    class="spinner-border-sm text-white mr-1"
                  />
                  <strong>{{ isGenerating ? "Fetching" : btnText }}</strong>
                </CButton>
              </div>
            </form>
          </ValidationObserver>
        </CCardBody>
      </CCard>
    </div>
    <div v-if="!isProcessing && htmlString">
      <div class="d-flex justify-content-between">
        <h5 class="text-primary">Preview</h5>
        <div>
          <button
            name="btn-edit"
            class="btn text-primary"
            v-if="!isEditMode"
            @click="startEditMode"
          >
            <span class="mr-1">Edit</span>
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
          <button
            name="btn-regenerate"
            class="btn text-primary"
            @click="regenerateTemplate"
          >
            <span class="mr-1">Regenerate</span>
            <i class="fa-solid fa-arrows-rotate"></i>
          </button>
          <button
            name="btn-cancel"
            class="btn text-primary"
            v-if="isEditMode"
            @click="stopEditMode"
          >
            <span class="mr-1">Cancel</span>
            <i class="fa-solid fa-xmark"></i>
          </button>
          <button
            class="btn text-primary"
            @click="saveTemplate"
            name="btn-save"
          >
            <span class="mr-1">Save</span>
            <i class="fa-regular fa-floppy-disk"></i>
          </button>
        </div>
      </div>
      <CCard>
        <CCardBody class="preview-body">
          <div v-if="htmlString">
            <iframe
              id="iframe"
              ref="iframe"
              :srcdoc="htmlString"
              onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));'
              style="height: 100vh; width: 100%; border: none; overflow: hidden"
            ></iframe>
          </div>
          <img
            class="place_holder_img"
            src="/img/website_placeholder.png"
            v-if="!htmlString"
            width="100%"
            alt="No Data"
          />
        </CCardBody>
      </CCard>
    </div>
    <div v-else-if="isProcessing">
      <div class="container-md col-lg-8 col-md-8 position-relative">
        <h4 class="text-primary">Profile Page</h4>
        <p class="h3 generation text-muted">Generation In-Progress</p>
        <div class="skeleton-card"></div>
      </div>
    </div>
    <CSidebar fixed :minimize="false" :show.sync="isShowEdit" v-if="isShowEdit">
      <CSidebarHeader class="bg-clr" :withSubheader="true">
        <div>
          <h4 class="text-primary">Edit {{ selectedElement.tagName }}</h4>
        </div>
      </CSidebarHeader>
      <div class="m-3">
        <div v-if="['IMG', 'SECTION'].includes(selectedElement.tagName)">
          <label
            class="text-primary required"
            v-if="selectedElement.tagName === 'IMG'"
            >Image</label
          >
          <label class="text-primary required" v-else>Background Image</label>
          <input
            ref="input_file"
            name="input_file"
            type="file"
            accept="image/*"
            @change="handleSelectedFile"
          />
        </div>
        <div v-else>
          <label class="text-primary required">Text Content</label>
          <TextAreaInput
            name="textContent"
            :value="selectedElement.textContent"
            @change="handleTextInput"
            :error="selectedElement.error"
            :rows="5"
          />
        </div>
        <div
          v-if="selectedElement.color && !selectedElement.isBackIMG"
          class="mt-3"
        >
          <label class="text-primary required mb-0">Text Color</label>
          <LvColorpicker
            :value="selectedElement.color"
            hidePalette
            @input="handleColorChange"
          />
        </div>

        <div class="d-flex justify-content-end mt-3">
          <CButton
            color="primary"
            class="mx-2"
            variant="outline"
            @click="updateElement(false)"
            >Cancel</CButton
          >
          <CButton class="mx-2" color="secondary" @click="deleteElement(true)"
            >Delete</CButton
          >
          <CButton color="primary" @click="updateElement(true)">Update</CButton>
        </div>
      </div>
    </CSidebar>
    <CropperModal
      v-if="cropperModal.isShowPopup"
      :isShowPopup="cropperModal.isShowPopup"
      :fileData="cropperModal.fileData"
      modalColor="primary"
      :buttonLabel="cropperModal.buttonLabel"
      @modalCallBack="cropperModalCallBack"
      :aspectRatio="cropperModal.aspectRatio"
    />
  </div>
</template>
<script>
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import TextInput from "@/components/reusable/Fields/TextInput";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import CropperModal from "@/components/reusable/CropperModal";
extend("required", { ...required, message: "This field is required" });
import LvColorpicker from "lightvue/color-picker";
export default {
  props: {
    isEditModeInfo: {
      type: Boolean,
      default: false,
    },
    htmlFileString: {
      type: String,
      default: null,
    },
  },
  components: {
    TextAreaInput,
    TextInput,
    CropperModal,
    LvColorpicker,
  },
  data() {
    return {
      acceptedFile: ["pdf", "doc", "docx"],
      htmlString: null,
      userData: {
        // description: "English course for medical professionals",
      },
      btnText: "Submit 🚀",
      isGenerating: false,
      isShowEdit: false,
      isEditMode: false,
      selectedElement: {},
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
        aspectRatio: 0,
        data: null,
      },
      templateRes: null,
    };
  },
  computed: {
    ...mapGetters(["getOrgIDFromAccessToken", "generatedPartnerRes"]),
    isCompleted() {
      return this.generatedPartnerRes?.completed || false;
    },
    isSubmitted() {
      return this.generatedPartnerRes?.submitted || false;
    },
    isProcessing() {
      return this.generatedPartnerRes
        ? !this.htmlString && this.generatedPartnerRes.completed === false
        : false;
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "generateTemplate",
      "createPartnerPage",
      "getHTML",
      "getGenerateResponse",
    ]),
    async onSubmit() {
      const isValid = await this.$refs.templateForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      this.isGenerating = true;
      this.htmlString = null;
      const payload = {
        name: this.userData?.name,
        description: this.userData?.description,
        file: this.userData?.file,
        organisation_id: this.getOrgIDFromAccessToken,
      };
      this.generateTemplate(payload).then((res) => {
        this.isGenerating = false;
        if (res.status === 200) {
          // setTimeout(() => {
          //   this.getGenerationResponse();
          // }, 5000);
          const partners_page = res.data.partners_page;
          if (partners_page.completed) {
            const message = JSON.parse(
              res.data?.partners_page?.assistant_message
            );

            const parsedRes = message?.content[0]?.text?.value;
            const htmlString = this.extractCodeBlocks(parsedRes);
            if (htmlString) {
              this.htmlString = htmlString;
              setTimeout(() => {
                this.getImageAsBase64();
                this.removeHref();
              }, 500);
            }
          } else {
            this.$store.commit("SET_GENERATED_PARTNER_RES", [partners_page]);
          }
        }
      });
    },
    getGenerationResponse() {
      const organisation_id = this.getOrgIDFromAccessToken;
      this.getGenerateResponse(organisation_id).then((res) => {
        if (res?.length && res[0]?.assistant_message && !res[0].submitted) {
          const parsedRes = res[0]?.assistant_message?.content[0]?.text?.value;
          const htmlString = this.extractCodeBlocks(parsedRes);
          if (htmlString) {
            this.htmlString = htmlString;
            setTimeout(() => {
              this.getImageAsBase64();
              this.removeHref();
            }, 500);
          }
        } else if (this.htmlFileString) {
          this.htmlString = this.htmlFileString;
        }
      });
    },
    handleChangeInput(name, value) {
      Vue.set(this.userData, name, value);
    },
    handleSelectedFile(event) {
      const ref = `input_file`;
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 10) {
        const file = event.target.files[0];
        if (file.type.startsWith("image/")) {
          this.cropperModal.fileData = file;
          this.cropperModal.isShowPopup = true;
        } else {
          this.$refs[ref].value = "";
          this.showToast({
            class: "bg-danger text-white",
            message: "Please select Image",
          });
          return;
        }
      } else {
        this.$refs[ref].value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: "File too Big, please select a file less than 2mb",
        });
        return;
      }
    },
    handleColorChange(value) {
      this.selectedElement.color = value;
    },
    handleTextInput(name, value) {
      Vue.set(this.selectedElement, name, value);
    },
    async cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
        const ref = `input_file`;
        this.$refs[ref].value = "";
      } else {
        let { fileBlob: file, fileName } = data;
        if (file) {
          try {
            file = await this.convertToBase64(file);
          } catch (error) {
            console.error("Error converting to base64:", error);
          }
        }
        Vue.set(this.selectedElement, "file", file);
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
      }
    },
    convertToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          const base64String = reader.result;
          const base64DataUrl = base64String.split(",")[1];
          resolve(base64DataUrl);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });
    },
    extractCodeBlocks(text) {
      // Regular expression to match code blocks enclosed within triple backticks (`) or triple tildes (~)
      const regex = /```([\s\S]+?)```|~~~([\s\S]+?)~~~/g;

      let matches = [];
      let match;

      // Iterate over matches found in the text
      while ((match = regex.exec(text)) !== null) {
        // Check if the match exists and push it to the matches array
        if (match[1]) {
          matches.push(match[1].trim().replace("html\n", ""));
        } else if (match[2]) {
          matches.push(match[2].trim().replace("html\n", ""));
        }
      }

      return matches;
    },
    getImageAsBase64() {
      const elementsInsideIframe = this.getIframeElements("*");
      elementsInsideIframe.forEach((element) => {
        const isBackIMG = this.checkIMGBackground(element);
        let imgURL = isBackIMG ? isBackIMG : element.src;
        const image = new Image();
        image.crossOrigin = "Anonymous";
        image.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0);
          const dataURL = canvas.toDataURL("image/png");
          if (isBackIMG) {
            element.style.backgroundImage = `url(${dataURL})`;
            element.style.maxHeight = "250px";
          } else {
            element.src = dataURL;
          }
        };
        image.src = imgURL;
      });
    },
    removeHref() {
      const iframe = document.getElementById("iframe");
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      const elementsInsideIframe =
        iframeDocument.querySelectorAll("a,img,section");
      elementsInsideIframe.forEach((element) => {
        if (element.tagName === "IMG") {
          element.style.width = "100%";
        } else if (element.tagName === "SECTION") {
          element.style.marginBottom = "10px";
        } else element.href = "javascript:void(0)";
      });
    },
    getIframeElements(
      tags = "p, img, h1, h2, h3, h4, h5, h6, button, a, section, li"
    ) {
      const iframe = document.getElementById("iframe");
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      // Get all elements inside the iframe
      const elementsInsideIframe = iframeDocument.querySelectorAll(tags);
      return elementsInsideIframe;
    },
    startEditMode() {
      this.isEditMode = true;
      const elementsInsideIframe = this.getIframeElements();
      elementsInsideIframe.forEach((element) => {
        const parentNode = element.parentNode;
        const isParentSection = parentNode.tagName === "SECTION";
        const isSection = element.tagName === "SECTION";
        let isBackIMG = false;
        if (isSection) {
          isBackIMG = this.checkIMGBackground(element);
        }
        // if (!isSection || isBackIMG) {
          element.addEventListener("mouseover", this.addBorder);
          element.addEventListener("mouseout", this.removeBorder);
          element.addEventListener("click", this.setSelectedElement);
        // }
      });
    },
    addBorder(event) {
      const ele = event.target;
      ele.classList.add("border"); // Blue border
      ele.classList.add("border-info"); // Blue border
    },
    removeBorder(event) {
      const ele = event.target;
      ele.classList.remove("border");
      ele.classList.remove("border-info");
    },
    stopEditMode() {
      this.isEditMode = false;
      this.removeListeners();
    },
    saveTemplate() {
      this.stopEditMode();

      const htmlBlob = this.generateHTMLBlob();
      const organisation_id = this.getOrgIDFromAccessToken;
      const payload = {
        file: htmlBlob,
        fileName: `profile_org_${organisation_id}.html`,
      };
      this.$emit("saveTemplate", payload);
    },
    regenerateTemplate() {
      this.htmlString = "";
    },
    setSelectedElement(event) {
      let ele = event.target;
      this.isShowEdit = true;
      if (ele.parentNode.tagName === "SECTION") {
        ele = ele.parentNode;
      }
      this.selectedElement.element = ele;
      this.selectedElement.tagName = ele.tagName;
      this.selectedElement.textContent = ele.textContent || null;
      this.selectedElement.color = this.getColorCodeElement(ele);
      this.selectedElement.isBackIMG = this.checkIMGBackground(ele);
      this.selectedElement.isLogo = this.checkIMGLogo(ele);
      this.selectedElement.isIMG = ele.src;
    },
    getColorCodeElement(ele) {
      const computedStyle = window.getComputedStyle(ele);
      const color = computedStyle.getPropertyValue("color");
      return color ? this.rgbToHex(color) : null;
    },
    checkIMGLogo(element) {
      return element.tagName === "IMG"
        ? element.alt?.toLowerCase().includes("logo")
        : false;
    },
    checkIMGBackground(element) {
      const computedStyle = window.getComputedStyle(element);
      const backgroundImage =
        computedStyle.getPropertyValue("background-image");
      return backgroundImage && backgroundImage !== "none"
        ? backgroundImage.match(/url\(['"]?(.*?)['"]?\)/)[1]
        : false;
    },
    updateElement(isUpdate) {
      this.isShowEdit = false;
      if (isUpdate) {
        if (this.selectedElement.isIMG) {
          this.selectedElement.element.src = `data:image/png;base64,${this.selectedElement.file}`;
          if (this.selectedElement.isLogo)
            this.selectedElement.element.style.width = "auto";
        }
        if (this.selectedElement.isBackIMG) {
          this.selectedElement.element.style.backgroundImage = `url(data:image/png;base64,${this.selectedElement.file})`;
          this.selectedElement.element.style.backgroundRepeat = "no-repeat";
          this.selectedElement.element.style.backgroundPosition = "center";
          this.selectedElement.element.style.backgroundSize = "cover";
          this.selectedElement.element.style.minHeight = "200px";
          this.selectedElement.element.style.maxHeight = "250px";
        }
        if (this.selectedElement.color) {
          this.selectedElement.element.style.color = this.selectedElement.color;
        }
        if (this.selectedElement.textContent&&!this.selectedElement.isBackIMG) {
          this.selectedElement.element.textContent =
            this.selectedElement.textContent;
        }
      }
    },

    deleteElement() {
      this.selectedElement.element.remove();
    },
    listenerEvent() {},
    removeListeners() {
      const elementsInsideIframe = this.getIframeElements();
      elementsInsideIframe.forEach((element) => {
        element.removeEventListener("mouseover", this.addBorder);
        element.removeEventListener("mouseout", this.removeBorder);
        element.removeEventListener("click", this.setSelectedElement);
      });
    },
    selectedFile(event) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = file.name.split(".").pop();
        const fileTypeString = this.acceptedFile;
        if (fileTypeString.includes(fileExt)) {
          this.userData = {
            ...this.userData,
            file: {
              file,
              fileName: file?.name,
            },
          };
        } else {
          let fileTypeError = fileTypeString;
          this.$refs.fileref.value = "";
          this.showToast({
            class: "bg-danger text-white",
            message: `Allowed File Type ${fileTypeError}`,
          });
          return;
        }
      } else {
        this.$refs.fileref.value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: "File too Big, please select a file less than 2mb",
        });
        return;
      }
    },
    arrayToComma(data) {
      return data?.map((v) => `.${v}`).join(", ");
    },
    rgbToHex(rgb) {
      const values = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      return (
        "#" +
        (
          (1 << 24) +
          (parseInt(values[1]) << 16) +
          (parseInt(values[2]) << 8) +
          parseInt(values[3])
        )
          .toString(16)
          .slice(1)
      );
    },
    generateHTMLBlob() {
      const iframe = document.getElementById("iframe");

      // Get the content of the iframe
      const iframeContent = iframe.contentDocument.documentElement.outerHTML;

      // Create a Blob from the iframe content
      const blob = new Blob([iframeContent], { type: "text/html" });
      return blob;
    },
    convertHTMLtoDoc(url) {
      this.getHTML(url).then((result) => {
        this.htmlString = result?.data;
      });
    },
  },
  mounted() {
    this.getGenerationResponse();
  },
};
</script>
<style lang="scss" scoped>
.preview,
.preview-body {
  padding: 22px 24px 20px;
  background-color: var(--modal-background);
  border-radius: 3px;
  box-shadow: 0 17px 50px 0 rgba(var(--shadow-rgb), 0.19),
    0 12px 15px 0 rgba(var(--shadow-rgb), 0.24);
  min-height: 300px;
}
.place_holder_img {
  height: 300px;
  // width: auto;
  object-fit: cover;
}
.generation {
  position: absolute;
  top: 50%;
  left: 35%;
}
</style>
